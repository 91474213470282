import * as yup from 'yup';

export const CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA = yup.object().shape({
    address1: yup.string().required(),
    address2: yup.string().optional(),
    city: yup.string().required(),
    state: yup.string().required(),
    zipcode: yup.string().when('isZipBlocked', {
        is: false,
        then: yup.string().required(),
        otherwise: yup.string().optional()
    })
});
