import { Field, Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { forwardRef, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import FormSelect from 'ui-kit/form-select/form-select';
import Text from 'ui-kit/text/text';

import { stateOptions } from 'const/options';

import { CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA } from 'schema/create-birdi-account-shipping-information.schema';

import { MembershipPersonalDetailsFormValues, MembershipRegistrationFormProps } from 'types/membership';

import { noop } from 'util/function';

import { useGlobalLink } from 'hooks/useGlobalLink';

import './membership-registration-shipping-form.style.scss';

interface StateChangeEvent {
    key: string;
    option: {
        key: string;
        label: string;
        value: string;
    };
}

const MembershipRegistrationShippingForm = forwardRef(
    (
        {
            onCancel,
            onSubmit,
            localesBaseKey,
            addressLabel = 'Address',
            isZipBlocked = true,
            initialValues,
            onStateChange = noop
        }: MembershipRegistrationFormProps,
        ref
    ) => {
        const { t } = useTranslation();
        const formName = 'Shipping Information';
        const globalLink = useGlobalLink();

        const shippingAddressLabel =
            addressLabel === 'Address'
                ? t('membership.shipping.form.address1')
                : t('forms.membership.paymentMethod.labels.billingAddress');

        if (!localesBaseKey) {
            localesBaseKey = 'pages.profile';
        }

        useEffect(() => {
            globalLink.setFormName(formName);
            globalLink.setFlowName('MembershipRegistrationShipping');
            globalLink.setStepName(t('registration.profileTitle'));
        }, [globalLink, t]);

        const handleStateChange = (e: StateChangeEvent) => {
            const stateValue = e?.option?.value;

            if (onStateChange && typeof onStateChange === 'function') {
                onStateChange(stateValue);
            }
        };

        return (
            <Formik
                onSubmit={onSubmit}
                innerRef={ref as React.MutableRefObject<FormikProps<MembershipPersonalDetailsFormValues>>}
                validationSchema={CREATE_BIRDI_ACCOUNT_SHIPPING_INFO_SCHEMA}
                enableReinitialize={true}
                initialValues={{
                    address1: initialValues?.address1,
                    address2: initialValues?.address2,
                    city: initialValues?.city,
                    state: initialValues?.state,
                    zipcode: initialValues?.zipcode,
                    isZipBlocked
                }}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldTouched,
                    setFieldValue,
                    validateField
                }) => {
                    const handleInputChange = async (fieldName: string, value: any) => {
                        await setFieldValue(fieldName, value);
                        validateField(fieldName);
                    };

                    return (
                        <Form
                            id="membership-registration-shipping-form"
                            data-ga-form-name={formName}
                            onSubmit={handleSubmit}
                            autoComplete="off"
                            className="membership-registration-shipping-form"
                        >
                            <div className="membership-registration-contact-form-header">
                                <div>
                                    <div className="membership-registration-contact-form-header-pill"></div>
                                    <div className="contact-form-title">{t('membership.shipping.infoHeading')}</div>
                                </div>
                            </div>
                            <Row className="membership-registration-shipping-form-mt-3">
                                <Col>
                                    <Text
                                        name="address1"
                                        label={shippingAddressLabel}
                                        onChange={(e) => handleInputChange('address1', e.target.value)}
                                        onBlur={(e) => {
                                            setFieldTouched('address1', true);
                                            handleBlur(e);
                                        }}
                                        touched={!!errors?.address1 || touched.address1}
                                        value={values?.address1}
                                        errors={
                                            errors?.address1
                                                ? errors?.address1.trim() === 'Address Not Found.'
                                                    ? t('forms.errorMessages.invalidAddress')
                                                    : t('forms.membership.shippingInformation.errors.requiredAddress')
                                                : undefined
                                        }
                                        onFocus={() =>
                                            globalLink.handleFieldFocus(t('membership.shipping.form.address1'))
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="membership-registration-shipping-form-mt-3">
                                <Col>
                                    <Text
                                        name="address2"
                                        label={t('membership.shipping.form.address2')}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            setFieldTouched('address2', true);
                                            handleBlur(e);
                                        }}
                                        touched={touched.address2}
                                        value={values?.address2}
                                        errors={
                                            errors?.address2
                                                ? errors?.address2 === 'Address2 required.'
                                                    ? t('forms.errorMessages.invalidAddress2')
                                                    : errors?.address2
                                                : undefined
                                        }
                                        onFocus={() =>
                                            globalLink.handleFieldFocus(t('membership.shipping.form.address2'))
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="membership-registration-shipping-form-address">
                                <Col className="mb-3" sm={12} md={4}>
                                    <Text
                                        name="city"
                                        label={t('membership.shipping.form.city')}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            setFieldTouched('city', true);
                                            handleBlur(e);
                                        }}
                                        touched={!!errors?.city || touched.city}
                                        value={values?.city}
                                        errors={
                                            errors?.city
                                                ? errors.city === 'Invalid City.'
                                                    ? t('forms.errorMessages.invalidCity')
                                                    : t('forms.membership.shippingInformation.errors.requiredCity')
                                                : undefined
                                        }
                                        onFocus={() => globalLink.handleFieldFocus(t('membership.shipping.form.city'))}
                                    />
                                </Col>
                                <Col>
                                    <Field
                                        id="state"
                                        name="state"
                                        options={stateOptions}
                                        component={FormSelect}
                                        value={values.state}
                                        placeholder={t('membership.shipping.form.state')}
                                        errors={
                                            errors?.state
                                                ? errors.state === 'Invalid State.'
                                                    ? t('forms.errorMessages.invalidState')
                                                    : t('forms.membership.shippingInformation.errors.requiredState')
                                                : undefined
                                        }
                                        touched={!!errors?.state || touched.state}
                                        onChange={(e: StateChangeEvent) => {
                                            handleStateChange(e);
                                            setFieldValue('state', e.option.value);
                                        }}
                                        onFocus={() => globalLink.handleFieldFocus(t('membership.shipping.form.state'))}
                                    />
                                </Col>
                                <Col>
                                    <Text
                                        name="zipcode"
                                        label={t('membership.shipping.form.zipCode')}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            setFieldTouched('zipcode', true);
                                            handleBlur(e);
                                        }}
                                        touched={!!errors?.zipcode || touched.zipcode}
                                        value={values?.zipcode}
                                        disabled={isZipBlocked}
                                        errors={
                                            errors?.zipcode
                                                ? errors.zipcode === 'Invalid Zip Code.'
                                                    ? t('forms.errorMessages.invalidZipCode')
                                                    : t('forms.errorMessages.requiredField', {
                                                          label: t(`${localesBaseKey}.addAddress.zipCode`)
                                                      })
                                                : undefined
                                        }
                                        maxLength={10}
                                        onFocus={() =>
                                            globalLink.handleFieldFocus(t('membership.shipping.form.zipCode'))
                                        }
                                    />
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default MembershipRegistrationShippingForm;
